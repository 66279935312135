import Vue from 'vue';
import { ActionTree } from 'vuex';
import { LOGIN, LOGOUT } from '@/store/auth/mutation-types';
import { AuthState } from '@/types/store/AuthState';
import { RootState } from '@/types/store/RootState';

const actions: ActionTree<AuthState, RootState> = {
  async login({ dispatch }, payload): Promise<Error|Promise<void>> {
    // Reset Authorization header if it is still set
    if (Vue.prototype.$api?.defaults?.headers?.common?.Authorization) {
      Vue.prototype.$api.defaults.headers.common.Authorization = undefined;
    }
    try {
      const response = await Vue.prototype.$api.post('/api/login_check', {
        username: payload.email,
        password: payload.password,
      });

      if (response) {
        if (response.status !== 200) {
          // If status is not 200, create Promise and reject immediately.
          return new Promise<Error>((resolve, reject) => {
            reject(new Error('Request failed'));
          });
        }
        if (!response?.data?.token || !response?.data?.user) {
          // If needed date is not in response, create Promise and reject immediately.
          return new Promise<Error>(((resolve, reject) => {
            reject(new Error('Wrong data'));
          }));
        }

        // If we're in browser, store data in LocalStorage.
        if (window?.localStorage) {
          window.localStorage.setItem('user', JSON.stringify(response.data));
        }

        return dispatch('getUser', response.data);
      }

      return new Promise<Error>(((resolve, reject) => {
        reject(new Error('Server sent no response'));
      }));
    } catch (e) {
      return new Promise<Error>(((resolve, reject) => {
        reject(e);
      }));
    }
  },

  async refreshToken(): Promise<string|Promise<Error>> {
    try {
      // Check if there is a user object in localStorage and whether it has a refreshToken
      const storedUser = window.localStorage.getItem('user');
      if (storedUser) {
        const user = JSON.parse(storedUser);
        if (user.refresh_token) {
          // Refresh the token, set it on $api headers and return new token
          const { data } = await Vue.prototype.$api.post('/api/token/refresh', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            refresh_token: user.refresh_token,
          });

          Vue.prototype.$api.defaults.headers.common.Authorization = `Bearer ${data.token}`;
          return data.token;
        }
      }
      return '';
    } catch (e) {
      return new Promise<Error>(((resolve, reject) => {
        reject(e);
      }));
    }
  },

  async getUser({ commit }, payload): Promise<void|Promise<Error>> {
    Vue.prototype.$api.defaults.headers.common.Authorization = `Bearer ${payload.token}`;

    const { data: user } = await Vue.prototype.$api.get(payload.user);

    // Only log in if user is Admin
    if (user?.roles.indexOf('ROLE_SUPER_ADMIN') > -1 || user?.roles.indexOf('ROLE_ADMIN') > -1) {
      return commit(LOGIN, user);
    }

    return new Promise<Error>(((resolve, reject) => {
      reject(new Error('Access denied'));
    }));
  },

  async logout({ commit }): Promise<void> {
    Vue.prototype.$api.defaults.headers.common.Authorization = undefined;

    commit(LOGOUT);
  },
};

export default actions;
